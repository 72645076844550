
import { Task, TaskStatus } from "@/store/modules/task.store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatDate } from "@/utils/formatters";

@Component({
    components: {
        UserImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class PageSellerPartTask extends Vue {
    @Prop({ required: true }) readonly task!: Task;

    formatDate = formatDate;

    styles: { [key in TaskStatus]: { borderColor: string; backgroundColor: string; color: string } } = {
        TODO: { borderColor: "#F1F2F7", backgroundColor: "#F1F2F7", color: "#333" },
        REQUESTED: { borderColor: "#FFCA54", backgroundColor: "#FFCA54", color: "#fff" },
        PLANNED: { borderColor: "#23BACF", backgroundColor: "#23BACF", color: "#fff" },
        DONE: { borderColor: "#1BCFA9", backgroundColor: "#1BCFA9", color: "#fff" },
        NA: { borderColor: "#8C8E90", backgroundColor: "#8C8E90", color: "#fff" },
        WAITING: { borderColor: "#FF6A5B", backgroundColor: "#FF6A5B", color: "#fff" },
    };
}
